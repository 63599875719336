import React from "react";
import { baseUrl } from "../../helpers/baseUrl";
import { Link } from "react-router-dom";
import ContactForm from "../../components/ContactForm";

const CorporateTraining = () => {
  let imgPath = baseUrl();
  return (
    <div>
      <div className="flex flex-wrap items-center justify-center pt-5">
        <div
          className="w-full md:w-1/2 flex justify-center items-center overflow-hidden container"
          data-aos="fade-right"
        >
          <img
            src={`${imgPath}/assets/img/programsImg/about_01.jpg`}
            alt="Diagram"
            className="max-w-full object-contain rounded-lg"
          />
        </div>

        <div className="w-full md:w-1/2 mt-4 md:mt-0 px-4 md:px-8" data-aos="fade-left">
          <p className="text-gray-800 text-xl font-semibold leading-relaxed md:leading-loose">
            Unlock Growth with Tailored Corporate Training by{" "}
            <strong className="font-bold text-gray-900">
              <span className="text-navy">Mind Coders</span>
            </strong>{" "}
          </p>
          <p>
            At MindCoders, we enable businesses to scale new heights through
            customized corporate training programs, designed to upgrade employee
            skills for organizational success. Our aim is to arm your workforce
            with the best knowledge in town, keeping them ahead in today's
            rapidly evolving digital landscape.
          </p>
        </div>
      </div>

      <section>
        <div className="w-full px-4 md:px-8 mb-5" data-aos="fade-left">
          <p className="text-gray-800 text-xl font-semibold leading-relaxed md:leading-loose">
            Why{" "}
            <strong className="font-bold text-gray-900">
              <span className="text-navy">Mind Coders</span>
            </strong>{" "}
            for Corporate Training?
          </p>
          <ul className="list-disc list-inside text-gray-800">
            <li>
              <b>Customized Programs:</b> Every business is unique. We design
              training modules that align with your company's specific goals,
              industry, and skill gaps.
            </li>
            <li>
              <b>Expert Trainers:</b> Our trainers are industry professionals
              with years of hands-on experience, ensuring practical insights and
              actionable knowledge.
            </li>
            <li>
              <b>Real-World Projects:</b> Employees get to work on live
              projects, enabling them to apply their learning directly to
              business challenges.
            </li>
            <li>
              <b>Flexible Learning:</b> We offer both in-person and online
              training, making it convenient for your teams to learn without
              disrupting their workflow.
            </li>
          </ul>
        </div>
      </section>

      <section className="py-5 bg-gray-100">
        <div className="container mx-auto px-4">
          {/* Heading Section */}
          <div className="flex flex-col items-center">
            <h2
              className="text-2xl md:text-4xl mb-3 font-light text-gray-800 border-b-0 mx-auto aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              Benefits of Our{" "}
              <strong className="font-medium text-[#172554]">
                Corporate Training
              </strong>{" "}
            </h2>
          </div>

          {/* Items Section */}
          <div className="row join-list">
            {/* First Item */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 items-center text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <div className="ourgrowthImg" data-aos="flip-left">
                <img
                  src={`${imgPath}/assets/img/home-join/join1.webp`}
                  alt=""
                />
                <span className="s12 mt-3 rs-count">2000+</span>
              </div>
              <div className="text-gray-800 mt-2">
              <p className="font-semibold">Increased Productivity</p>
              <p>
                Upskilled employees are more likely to
                perform at improved efficiency and innovation.
              </p>
              </div>
            </div>

            {/* Second Item */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 items-center text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="ourgrowthImg" data-aos="flip-left">
                <img
                  alt=""
                  src={`${imgPath}/assets/img/home-join/join2.webp`}
                />
                <span className="s12 mt-3">100% Placed</span>
              </div>

              <div className="text-gray-800 mt-2">
                <p className="font-semibold">Retention and Engagement</p>
                <p>
                  Investment in employee growth will enhance the satisfaction of
                  the job and increase retention rates.
                </p>
              </div>
            </div>

            {/* Third Item */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 items-center text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="ourgrowthImg" data-aos="flip-left">
                <img
                  src={`${imgPath}/assets/img/home-join/join1.webp`}
                  alt=""
                />
                <span className="s12 mt-3 rs-count">2000+</span>
              </div>
              <div className="text-gray-800 mt-2">
                <p className="font-semibold">Competitive Advantage</p>
                <p>
                  Obtain a market lead by having the most updated industry
                  knowledge at your company's disposal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 cantainer overflow-hidden">
        <div className="container-xl">
          <div className="w-full px-8">
            <div
              className="aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="0"
            >
              <h2 className="text-2xl md:text-3xl font-semibold text-[#172554]">
              Success Stories
              </h2>
              <p
                className="mt-4 text-lg md:text-xl leading-relaxed aos-init aos-animate"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                Our corporate training has enabled companies across different industries to gain immense improvement in performance, teamwork, and innovation. Join forces with MindCoders to witness the transformative power of learning with your teams.
              </p>
            </div>

            {/* <div
              className="aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="0"
            >
              <h2 className="text-2xl md:text-3xl font-semibold text-[#172554]">
              Get in Touch
              </h2>
              <p
                className="mt-4 text-lg md:text-xl leading-relaxed aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                Want to level up your team? Reach us now at +91-7674040233 or visit us at 206, Pearl Business Park, Bhanwarkua, Indore
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section className="contact-us ptb-60 position-relative">
        <div className="container ">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            <div>
              <div
                className="section-heading aos-init aos-animate"
                data-aos="fade-up"
              >
                <h4 className="h5 text-info">Have any queries?</h4>
                <h2 className="h3 text-color-navy">Get in Touch Today!</h2>
              </div>
              <div className="row justify-content-between pb-5">
                <div
                  className="col-sm-6 mb-4 mb-md-0 mb-lg-0 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={50}
                >
                  <div className="icon-box d-inline-block rounded-circle bg-primary-soft">
                    <i className="fas fa-phone fa-2x text-primary" />
                  </div>
                  <div className="contact-info">
                    <h5 className="h5 text-color-navy">Call Us</h5>
                    <p>
                      Questions about our course or pricing? Call for support
                    </p>
                    <a
                      href="tel:7674040233"
                      className="read-more-link text-decoration-none"
                    >
                      <i className="fas fa-phone me-2" />
                      7674040233
                    </a>{" "}
                    <br />
                    <a
                      href="tel:7389640233"
                      className="read-more-link text-decoration-none"
                    >
                      <i className="fas fa-phone me-2" />
                      7389640233
                    </a>
                  </div>
                </div>
                <div
                  className="col-sm-6 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="icon-box d-inline-block rounded-circle bg-danger-soft">
                    <i className="fas fa-headset fa-2x text-danger" />
                  </div>
                  <div className="contact-info">
                    <h5 className="h5 text-color-navy">Chat Us</h5>
                    <p>
                      Our support will help you from <br />
                      <strong>9am to 6pm IST.</strong>
                    </p>
                    <a
                      href="https://api.whatsapp.com/send?phone=7674040233"
                      target="_blank"
                      className="read-more-link text-decoration-none"
                      rel="noreferrer"
                    >
                      <i className="fas fa-comment me-2" /> Send Message
                    </a>{" "}
                    <br />
                    <a
                      href="mailto:hr@mindcoders.in"
                      target="_blank"
                      className="read-more-link text-decoration-none"
                      rel="noreferrer"
                    >
                      <i className="fa-solid fa-envelope me-2" /> Send Mail
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rounded-custom aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={150}
            >
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CorporateTraining;
